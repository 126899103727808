import React from 'react';
import {connect} from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
} from '@material-ui/icons';
import {UserAvatar} from "components/User/components";
import {connectUserStream} from "components/Meeting/actions";
import {getUserVideoSegments} from "components/Meeting/selectors";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  placeholder: {
    height: 600,
    width: 800,
  },
  members: {
    margin: 10,
  },
  segment: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  segmentContainer: {
    position: 'relative',
    height: 120,
    width: 160,
  },
});

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);
const protocol = process.env.REACT_APP_PROTOCOL;
const host = process.env.REACT_APP_HOST
function Participant (props){
  const {
    connectUserStream,
    meetingId,
    presenterId,
    user,
    userSegments
  } = props;
  React.useEffect(()=>{
    connectUserStream(meetingId, user.id)
  },[connectUserStream, meetingId, user])
  const classes = useStyles();
  const segments = userSegments[user.id]
  const isPresenting = user.id === presenterId;
  return (
    <Grid key={user.id} item className={classes.members}>
        <Card className={classes.root} variant="outlined">
          <CardHeader title={`${user.fname} ${user.lname}`} />
          <Grid container justify="center">
            <Grid item>
              <CardContent className={classes.segmentContainer}>
                {!isPresenting && segments && segments.length > 0 ? (
                  <React.Fragment>
                    {segments.map((segment, idx) => (
                      <div key={idx} className={classes.segment}>
                        <video
                          width="160"
                          height="120"
                          src={`${protocol}://${host}/static/${user.id}/${segment}`}
                        />
                      </div>
                    ))}
                  </React.Fragment>
                ) : (
                  <Grid container justify="center">
                    <Grid item>
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                      >
                        <UserAvatar height="120" width="160" avatar={user.avatar} />
                      </StyledBadge>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Grid>
          </Grid>
          <CardActions>
            <Grid container justify="center">
              <Grid item>
                <Tooltip title="Audio">
                  <IconButton size="small">
                    {true ? <VolumeUpIcon /> : <VolumeOffIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
    </Grid>
  );
}

export default connect (
  state => ({
    userSegments: getUserVideoSegments(state)
  }),
  {connectUserStream}
)(Participant)
