import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const IssuesToolbar = props => {
  let history = useHistory();
  function handleClick() {
    history.push("/issues-form");
  }
  const { className, onSearchInputChange, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/*
          <Button className={classes.importButton}>Import</Button>
          <Button className={classes.exportButton}>Export</Button>
        */}
        <Button
          id="add-issue-button"
          onClick={handleClick}
          color="primary"
          variant="contained"
        >
          Add Issue
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search issue"
          onChange={(e)=>onSearchInputChange(e.target.value)}
        />
      </div>
    </div>
  );
};

IssuesToolbar.propTypes = {
  className: PropTypes.string,
  onSearchInputChange: PropTypes.func
};

export default IssuesToolbar;
