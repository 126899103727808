const initialState = {
  issues: [],
  isLoading: true,
};

export function reducer(state=initialState, action){
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      }
    case "LOAD_ISSUES_LIST":
      return {
        ...state,
        issues: action.issues,
        isLoading: false,
      }
    case "ISSUE_UPDATED":
      return {
        ...state,
        issues: state.issues.map(issue=>issue.id === action.issue.id?action.issue:issue)
      }
    default:
      return state;
  }
}
