export function reducer (state={
  ws: undefined,
  meetingInfoWS: undefined,
  segments: [],
  userSegments: {},
  audio: localStorage.getItem('audio') === "true",
  video: localStorage.getItem('video') === "true",
  isRecording: true,
  isScreenCapture: false,
  activeUserIdx: {},
  meetingInfo: {
    presenterId: '',
    users: [],
  },
  stream: undefined,
}, action) {
  switch (action.type){
    case "SET_MEDIA_SOURCE":
      return {
        ...state,
        mediaSource: action.mediaSource,
      }
    case "ADD_USER_ACTIVE_INDEX":
      return {
        ...state,
        activeUserIdx: {
          ...state.activeUserIdx,
          [action.id]: state.activeUserIdx[action.id] ?
            state.activeUserIdx[action.id]
            : 1
        }
      }
    case "ADD_ACTIVE_INDEX":
      return {
        ...state,
        activeIdx: state.activeIdx + 1
      }
    case "ADD_MEETING_SEGMENT":
      return {
        ...state,
        segments: [...state.segments, action.segment],
      }
    case "CLEAR_MEETING_SEGMENTS":
      return {
        ...state,
        segments: [],
      }
    case "ADD_USER_SEGMENT":
      return {
        ...state,
        userSegments: {
          ...state.userSegments,
          [action.userId]: state.userSegments[action.userId] ?
            [...state.userSegments[action.userId], action.segment] :
            [action.segment]
        },
      }
    case "TOGGLE_IS_RECORDING":
      return {
        ...state,
        isRecording: !state.isRecording,
      }
    case "TOGGLE_AUDIO":
      localStorage.setItem('audio', !state.audio)
      return {
        ...state,
        audio: !state.audio,
      }
    case "TOGGLE_VIDEO":
      localStorage.setItem('video', !state.video)
      return {
        ...state,
        video: !state.video,
      }
    case "SET_MEETING_INFO":
      return {
        ...state,
        meetingInfo: action.info,
      }
    case "SET_STREAM":
      return {
        ...state,
        stream: action.stream
      }
    case "SET_MEETING_INFO_WS":
      return {
        ...state,
        meetingInfoWS: action.ws,
      }
    case "SET_MEETING_WS":
      return {
        ...state,
        ws: action.ws,
      }
    default:
      return state;
  }
}
