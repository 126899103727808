import React from 'react';
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { IssuesTable, IssuesToolbar } from './components';
import { handleSearchInputChanges, loadIssues, loadUsers } from "./actions.js";
import {
  getFilteredIssues,
  isLoading as areIssuesLoading
} from "./selectors.js";
import { getUsers, isLoading as areUsersLoading } from "../../components/User/selectors";
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const IssueList = (props) => {
  const classes = useStyles();
  const {
    areIssuesLoading,
    areUsersLoading,
    issues,
    loadUsers,
    loadIssues,
    users
  } = props;
  React.useEffect(() => {
    loadUsers();
    loadIssues();
    return ()=>{}
  }, [loadIssues, loadUsers])
  return (
    <div className={classes.root}>
      <IssuesToolbar onSearchInputChange={props.handleSearchInputChanges} />
      <Paper className={classes.content}>
        {!(areIssuesLoading && areUsersLoading) && (
          <IssuesTable issues={issues} users={users} />
        )}
      </Paper>
    </div>
  );
};

export default connect(state=>({
  issues: getFilteredIssues(state),
  users: getUsers(state),
  areIssuesLoading: areIssuesLoading(state),
  areUsersLoading: areUsersLoading(state),
}),{
  handleSearchInputChanges, loadIssues, loadUsers,
})(IssueList);
