import React from 'react';
import {connect} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {loadUsers} from 'components/User/actions';
import {getAccount} from 'components/Account/selectors';
import { clearSegments, connectMeetingWS } from 'components/Meeting/actions';
import {
  getPresenterId,
  getMeetingInfo,
  getMeetingUsers,
  getUserVideoSegments,
} from "components/Meeting/selectors";

import {Participant} from "./components";
import {Presenter} from "./components";
import {UserPreview} from "./components";

function Meeting(props) {
  const {
    account,
    connectMeetingWS,
    loadUsers,
    match,
    meetingInfo,
    presenterId,
    users
  } = props;
  React.useEffect(()=>{
    loadUsers();
  }, [loadUsers])
  React.useEffect(()=> {
    connectMeetingWS(match.params.id, account.id)
  }, [account, connectMeetingWS, match])
  return (
    <Grid container>
      {!(meetingInfo && meetingInfo.users) ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <Grid item>
            <Presenter
              presenterId={props.presenterId}
              segments={presenterId ? props.userSegments[presenterId] : []}/>
          </Grid>
          <Grid item>
            <UserPreview
             account={account}
             clearSegments={props.clearSegments}
             presenterId={presenterId}
            />
            {users && users.filter(user=>user.id!==account.id).map(user=>(
              <Participant
                key={user.id}
                user={user}
                presenterId={presenterId}
                meetingId={match.params.id}
              />
            ))}
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}

export default connect(
  state => ({
    account: getAccount(state),
    presenterId: getPresenterId(state),
    userSegments: getUserVideoSegments(state),
    meetingInfo: getMeetingInfo(state),
    users: getMeetingUsers(state),
  }),
  {clearSegments, connectMeetingWS, loadUsers}
)(Meeting)
