import {authFetch} from '../../common/auth-fetch';

export function updateAccount(id,{
  avatar,
  firstName,
  lastName,
  title,
  email,
  password
}) {
  return async (dispatch) => {
    // dispatch(setLoading())
    authFetch(`/api/account/${id}`, {
      credentials: "include",
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        avatar: avatar,
        fname: firstName,
        lname: lastName,
        title,
        email,
      })
    }, async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "SIGNIN_SUCCESS",
          user: data,
        })
        localStorage.setItem('email', data.email)
        localStorage.setItem('fname', data.fname)
        localStorage.setItem('lname', data.lname)
        localStorage.setItem('title', data.title)
      } else {
        dispatch({type: 'SIGNIN_ERROR'});
      }
    })
 }
}

export function setUserAvatar(avatar){
  localStorage.setItem("avatar", avatar)
  return {
    type: "SET_USER_AVATAR",
    avatar,
  }
}
