import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {reducer as issueListReducer} from './views/IssueList/reducer'
import {signinReducer} from './views/SignIn/reducer'
import {reducer as issueBoardReducer} from './views/IssueBoard/reducer'
import {signupReducer} from './views/SignUp/reducer'
import {themeReducer} from './theme/reducer'
import {reducer as issueReducer} from './components/Issue/reducer';
import {reducer as meetingReducer} from './components/Meeting/reducer';
import {reducer as userReducer} from './components/User/reducer';
import {reducer as teamReducer} from './components/Team/reducer';

const rootReducer = combineReducers({
  issueBoardReducer,
  issueReducer,
  issueListReducer,
  meetingReducer,
  signinReducer,
  signupReducer,
  themeReducer,
  teamReducer,
  userReducer,
})

export default createStore(rootReducer, applyMiddleware(thunk));
