const initialState = {
  isError: false,
  isLoading: false,
};

export function signupReducer(state=initialState, action){
  switch (action.type) {
    case "SIGNUP_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case "SIGNUP_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    default:
      return state;
  }
}
