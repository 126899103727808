import React from "react";
import {
  Build as BuildIcon,
  Help as HelpIcon,
  LocalShipping as LocalShippingIcon,
  PriorityHigh as PriorityHighIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

export const ISSUE_STATUS = ['To Do', 'In Progress', 'In Review', 'Shipped'];
export const getStatusFromName = name=>ISSUE_STATUS.indexOf(name);
export function getIconForStatus(idx){
  switch (idx){
    case 0:
      return (<PriorityHighIcon />)
    case 1:
      return (<BuildIcon />)
    case 2:
      return (<SearchIcon />)
    case 3:
      return (<LocalShippingIcon />)
    default:
      return (<HelpIcon />)
  }
}
export function getIconForStatusName(name){
  const style = {
    display: 'block',
    margin: '10px',
  }
  switch (name){
    case ISSUE_STATUS[0]:
      return (<PriorityHighIcon
                style={style}
                />)
    case ISSUE_STATUS[1]:
      return (<BuildIcon
              style={style}
         />)
    case ISSUE_STATUS[2]:
      return (<SearchIcon
              style={style}
         />)
    case ISSUE_STATUS[3]:
      return (<LocalShippingIcon
                style={style}
         />)
    default:
      return (<HelpIcon
                style={style}
         />)
  }
}
