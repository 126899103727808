function getUserState(state){
  return state.userReducer;
}

export function getUsers(state){
  return getUserState(state).users;
}

export function isLoading(state){
  return getUserState(state).isLoading;
}
