import React from 'react';
import IssueForm from "./IssueForm";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
export default (props) => (
  <Container maxWidth="sm">
    <Typography variant="h2">
      {props.issue && props.issue.id ? "Edit" : "Create"} an Issue
    </Typography>
    <IssueForm {...props}/>
  </Container>
)
