import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {MuteButton, VideoButton} from 'components/Meeting/components';
import {hasAudio, hasVideo} from "components/Meeting/selectors";
import {toggleAudio, toggleVideo} from "components/Meeting/actions";
import {
  AddToPhotos as AddToPhotosIcon,
  PersonAdd as PersonAddIcon,
} from '@material-ui/icons'
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  root: {
    marginTop: 25,
    minWidth: 275,
    minHeight: 175,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  joinMeeting: {
    paddingTop: 15,
  },
  settings: {
    paddingTop: 15,
  }
})
function MeetingLanding (props){
  const {hasAudio, hasVideo, toggleAudio, toggleVideo } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid>
      <Grid item>
        <Grid container spacing={3} justify="center">
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Create a New Meeting
                </Typography>
                <Divider/>
              </CardContent>
              <CardActions>
                <Grid container justify="center">
                  <Grid item>
                    <IconButton fontSize="large"
                      onClick={()=>{
                        history.push('/meeting/'+ uuidv4())
                      }}
                    >
                      <AddToPhotosIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Grid container justify="center">
                  <Grid item>
                    <Typography variant="h5" component="h2">
                      Join a Meeting
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid justify="center" className={classes.joinMeeting}
                   container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <PersonAddIcon />
                  </Grid>
                  <Grid item>
                    <TextField
                      placeholder="Enter Meeting ID"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button fullWidth size="small">Join</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid className={classes.settings}
          container alignItems="center" justify="center">
          <Grid item>
            <Typography>Video Settings:</Typography>
          </Grid>
          <Grid item>
            <MuteButton audio={hasAudio} onClick={toggleAudio} />
            <VideoButton video={hasVideo} onClick={toggleVideo} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default connect(
  state => ({
    hasAudio: hasAudio(state),
    hasVideo: hasVideo(state),
  }),
  {toggleAudio, toggleVideo}
)(MeetingLanding)
