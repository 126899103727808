import {authFetch} from "../../common/auth-fetch";
export function loadTeams(){
  return async dispatch => {
    await authFetch(`/api/teams`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    },
    async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "SET_TEAMS",
          teams:data
        })
      }
    })
  }
}
