import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  IconButton,
  Paper,
  ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import {loadIssues} from "components/Issue/actions";
import {getStatusFromName, getIconForStatusName, ISSUE_STATUS} from "components/Issue/components/Status/model";
import {getIssueBoardWS, getIssuesByStatus} from "./selectors";
import {getIconForPriority, ISSUE_PRIORITIES} from "components/Issue/components/Priority/const";
import {connectIssueBoardWS} from "./actions";
import {BoardToolbar} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  gridColumn: {
    minWidth: "200px",
    maxWidth: "300px",
  }
}));
// fake data generator
const getItems = (count, key, items) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `${items[k].id}`,
    primary: `${items[k].title}`,
    secondary: `${items[k].description}`,
    icon: getIconForPriority(items[k].priority),
    priority: items[k].priority,
  }));

const insertList = (list, idx, item) => {
  const result = Array.from(list);
  result.splice(idx, 0, item);
  return result;
}

const removeList = (list, idx) => {
  const result = Array.from(list);
  result.splice(idx, 1);
  return result;
}

const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

const reorder = (items, source, destination) => {
  const sourceId = source.droppableId;
  const destId = destination.droppableId;
  if (sourceId === destId){
    return {
      ...items,
      [destId]: reorderList(items[destId], source.index, destination.index),
    };
  }
  const item = items[sourceId][source.index];
  return {
    ...items,
    [destId]: insertList(items[destId], destination.index, item),
    [sourceId]: removeList(items[sourceId], source.index),
  };
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});
export function IssueBoard (props) {
  const {connectIssueBoardWS, issues, loadIssues, ws} = props;

  const sendBoardState = (boardState) => {
    ws && ws.send(JSON.stringify(boardState))
  }
  const styles = useStyles();
  React.useEffect(()=>{
    loadIssues();
    connectIssueBoardWS();
  }, [connectIssueBoardWS, loadIssues])

  const statuses = ISSUE_STATUS;
  const defaultItems = statuses.reduce((items, status) => {
    return {
      ...items,
      [status]: getItems(
                  issues[status] ? issues[status].length : 0,
                  status,
                  issues[status]
                ),
    }
  },{});
  const spacing = 2;
  const classes = useStyles();
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;
    const reordered = reorder(
      defaultItems,
      result.source,
      result.destination
    );
    //setItems(reordered);
    console.log(reordered)
    sendBoardState({
      issueId: defaultItems[source.droppableId][source.index].id,
      status: getStatusFromName(destination.droppableId),
    })
  };
  return (
    <React.Fragment>
      <BoardToolbar />
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={spacing}>
              {statuses.map((status, idx) => (
                <Grid key={status} item className={styles.gridColumn}>
                  <Paper>
                    <IssueColumn
                      icon={getIconForStatusName(status)}
                      status={status} items={defaultItems[status]} />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
    </React.Fragment>
  );
}
export function IssueColumn (props) {
  const history = useHistory();
  return (
    <Droppable droppableId={props.status}>
      {(provided, snapshot) => (
        <RootRef rootRef={provided.innerRef}>
          <List style={getListStyle(snapshot.isDraggingOver)}>
            <ListSubheader style={{borderBottom: "2px solid white"}}>
              <Grid container justify="center"
                direction="row" alignItems="center">
                <Grid item>
                  {props.icon}
                </Grid>
                <Grid item>
                  {`${props.status}`}
                </Grid>
              </Grid>
            </ListSubheader>
            {props.items.length === 0 ? (
              <ListItem>No issues</ListItem>
            ): (
              <React.Fragment>
                {props.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <React.Fragment>
                        <ListItem
                          ContainerComponent="li"
                          ContainerProps={{ ref: provided.innerRef }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <ListItemIcon>
                            <Tooltip title={ISSUE_PRIORITIES[item.priority]}>
                            {item.icon}
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText
                            primary={item.primary}
                            secondary={item.secondary}
                          />
                        <IconButton onClick={()=>history.push(`/issues-form/${item.id}`)}>
                            <EditIcon />
                          </IconButton>
                          <ListItemSecondaryAction>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
              </React.Fragment>
            )}
            {provided.placeholder}
          </List>
        </RootRef>
      )}
    </Droppable>
  );
}


export default connect(
  state=>({
    issues: getIssuesByStatus(state),
    ws: getIssueBoardWS(state)
  }),
  {connectIssueBoardWS, loadIssues}
)(IssueBoard)
