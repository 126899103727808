const initialState = {
  teams: [],
  isLoading: true,
};

export function reducer(state=initialState, action){
  switch (action.type) {
    case "SET_IS_TEAM_LOADING":
      return {
        ...state,
        isLoading: true,
      }
    case "SET_TEAMS":
      return {
        ...state,
        teams: action.teams,
        isLoading: false,
      }
    default:
      return state;
  }
}
