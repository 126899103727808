function getState(state){
  return state.issueListReducer;
}
export function getUsers(state){
  return getState(state).users;
}
export function getIssues(state){
  return getState(state).issues;
}
export function isLoading(state){
  return getState(state).isLoading;
}
export function getSearchTerm(state){
  return getState(state).searchTerm;
}
export function getFilteredIssues(state){
  const searchTerm = getSearchTerm(state);
  const issues = getIssues(state)
  if (!searchTerm){
    return issues
  }
  return issues.filter(
    issue=> issue.description.indexOf(searchTerm) >= 0
  );
}
