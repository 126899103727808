import {host} from "../../config";
export function signup({firstName, lastName, email, password}, history) {
  return async (dispatch) => {
    fetch(`//${host}/signup`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        pass:password,
        email,
        fname: firstName,
        lname: lastName,
      })
    }).then(async r => {
      if (r.status === 200){
        const parsed = await r.json()
        const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
        if (cookieDomain) {
          document.cookie = `ident=${parsed.token};Path=/;domain=${cookieDomain};`
        } else {
          document.cookie = `ident=${parsed.token};Path=/;`//domain=.co-lab.team;
        }
        dispatch({
          type: "SIGNUP_SUCCESS",
          user: parsed,
        })
        setTimeout(history.push('/'), 50)
      }
    }).catch(e => {
      dispatch({type: 'SIGNUP_ERROR'});
    })
 }
}
