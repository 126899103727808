import React from 'react';
import {connect} from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar, Toolbar, Hidden, IconButton, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import InputIcon from '@material-ui/icons/Input';
import {toggleDarkTheme} from "../../../../theme/actions";
import {logout} from "../../../../views/SignIn/actions";
import Notifications from "components/Notifications";


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const [notifications] = React.useState([]);
  const {
    className,
    logout,
    onSidebarOpen,
    toggleDarkTheme,
    ...rest
  } = props;
  const classes = useStyles();
  React.useEffect(()=>{
    const ws = new WebSocket(`${process.env.REACT_APP_PROTOCOL==="https"?"wss":"ws"}://${process.env.REACT_APP_HOST}/api/notifications`);
    ws.onmessage = (events) => {
      //setNotifications([...notifications, JSON.parse(events.data)])
    }
  }, [notifications]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <Tooltip title="co-lab!">
          <RouterLink to="/dashboard">
            <img
              height={55}
              alt="Logo"
              src="/images/logos/scientist-logo.svg"
            />
          </RouterLink>
        </Tooltip>
        <div className={classes.flexGrow} />
        <IconButton color="inherit"
          onClick={props.toggleDarkTheme}>
          <Tooltip title="toggle light/dark theme">
            <Brightness7Icon />
          </Tooltip>
        </IconButton>
        <Notifications notifications={notifications}>
          <Tooltip title="Your notifications">
            <NotificationsIcon />
          </Tooltip>
        </Notifications>
        <Tooltip title="Sign out">
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={props.logout}
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
        <Hidden lgUp>
          <Tooltip title="Open Menu">
            <IconButton
              id="open-menu-button"
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  logout: PropTypes.func,
  toggleDarkTheme: PropTypes.func,
};

export default connect(
  state => ({

  }), {
    logout, toggleDarkTheme
  }
)(Topbar);
