export function reducer (state={
  ws: undefined
}, action) {
  switch (action.type){
    case "SET_ISSUE_BOARD_WS":
      return {
        ...state,
        ws: action.ws,
      }
    default:
      return state;
  }
}
