import React from 'react';
import TeamForm from "./TeamForm";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
export default (props) => (
  <Container maxWidth="sm">
    <Typography variant="h3">Assemble a Team</Typography>
    <TeamForm {...props} />
  </Container>
)
