import React from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  AssignedToMe,
  Issues,
  TasksProgress,
} from './components';
import {getActiveIssues, getShippedIssues, getTaskProgress} from "./selectors";
import {loadIssues} from "../../components/Issue/actions"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = props => {
  const { loadIssues } = props;
  React.useEffect(()=>{
    loadIssues()
  }, [loadIssues])
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <AssignedToMe issues={props.activeIssues}/>
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={12}
        >
          <Issues shippedIssues={props.shippedIssues} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress taskProgress={props.taskProgress} />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(
  state => ({
    activeIssues: getActiveIssues(state),
    shippedIssues: getShippedIssues(state),
    taskProgress: getTaskProgress(state),
  }),{ loadIssues }
)(Dashboard);
