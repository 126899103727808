import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {googleAuthSuccess} from "./actions";

function GoogleAuth (props) {
  const { googleAuthSuccess } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const history = useHistory();
  React.useEffect(()=>{
    if (code && history) {
      googleAuthSuccess(code, history);
    }
  }, [code, googleAuthSuccess, history]);
  return (
    <div></div>
  )
}

export default connect(
  state => ({

  }),
  {googleAuthSuccess}
)(GoogleAuth)
