import React from 'react';
import {connect} from 'react-redux';
import { Switch, Redirect, useHistory } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {isLoggedIn} from './selectors'

import {
  Dashboard as DashboardView,
  GoogleAuth as GoogleAuthView,
  UserList as UserListView,
  Typography as TypographyView,
  TeamForm as TeamFormView,
  TeamList as TeamListView,
  Icons as IconsView,
  InviteForm as InviteFormView,
  IssueBoard as IssueBoardView,
  IssueForm as IssueFormView,
  Issue as IssueView,
  IssueList as IssueListView,
  Meeting as MeetingView,
  MeetingLanding as MeetingLandingView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';

const Routes = (props) => {
  const history = useHistory();
  if (props.isLoggedIn && history.location.pathname === '/'){
    history.push('dashboard');
  }
  return (
    <Switch>
      {!props.isLoggedIn ? (
        <React.Fragment>
          <RouteWithLayout
            component={GoogleAuthView}
            exact
            layout={MinimalLayout}
            path="/google-auth"
          />
          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up"
          />
          <Redirect
            exact
            from="/"
            to="/sign-in"
          />
          <RouteWithLayout
            component={SignInView}
            exact
            layout={MinimalLayout}
            path="/sign-in" />

        </React.Fragment>
      ) : (
        <React.Fragment>
          <RouteWithLayout
            component={DashboardView}
            exact
            layout={MainLayout}
            path="/dashboard"
          />
          <RouteWithLayout
            component={UserListView}
            exact
            layout={MainLayout}
            path="/users"
          />
          <RouteWithLayout
            component={InviteFormView}
            exact
            layout={MainLayout}
            path="/invite"
          />
          <RouteWithLayout
            component={IssueListView}
            exact
            layout={MainLayout}
            path="/issues"
            theme={props.theme}
          />
          <RouteWithLayout
            component={IssueBoardView}
            exact
            layout={MainLayout}
            path="/tracker"
          />
          <RouteWithLayout
            component={MeetingLandingView}
            exact
            layout={MainLayout}
            path="/meeting"
          />
          <RouteWithLayout
            component={MeetingView}
            exact
            layout={MainLayout}
            path="/meeting/:id"
          />
          <RouteWithLayout
            component={IssueFormView}
            exact
            layout={MainLayout}
            path="/issues-form"
          />
          <RouteWithLayout
            component={IssueFormView}
            exact
            layout={MainLayout}
            path="/issues-form/:id"
          />
          <RouteWithLayout
            component={IssueView}
            exact
            layout={MainLayout}
            path="/issue/:id"
          />
          <RouteWithLayout
            component={TeamFormView}
            exact
            layout={MainLayout}
            path="/teams-form"
          />
          <RouteWithLayout
            component={TeamFormView}
            exact
            layout={MainLayout}
            path="/teams-form/:id"
          />
          <RouteWithLayout
            component={TeamListView}
            exact
            layout={MainLayout}
            path="/teams"
          />
          <RouteWithLayout
            component={TypographyView}
            exact
            layout={MainLayout}
            path="/typography"
          />
          <RouteWithLayout
            component={IconsView}
            exact
            layout={MainLayout}
            path="/icons"
          />
          <RouteWithLayout
            component={AccountView}
            exact
            layout={MainLayout}
            path="/account"
          />
          <RouteWithLayout
            component={SettingsView}
            exact
            layout={MainLayout}
            path="/settings"
          />
          <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
        </React.Fragment>
      )}
    </Switch>
  );
};

export default connect(
  state => ({isLoggedIn: isLoggedIn(state)}),
  {},
)(Routes);
