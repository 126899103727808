import React from "react";
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TextField from '@material-ui/core/TextField';
import {
  Build as BuildIcon,
  LocalShipping as LocalShippingIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  Typography
} from '@material-ui/core';

export const status = [
  {
    icon: PriorityHighIcon,
    value: '0',
    label: 'To Do',
  },
  {
    icon: BuildIcon,
    value: '1',
    label: 'In Progress',
  },
  {
    icon: SearchIcon,
    value: '2',
    label: 'In Review',
  },
  {
    icon: LocalShippingIcon,
    value: '3',
    label: 'Shipped',
  },
];

export default (props) => {
  const {
    errors,
    touched,
    change,
    value,
    handleBlur,
  } = props
  return (
    <TextField
      className={
        errors.priority && touched.priority
          ? "text-input error"
          : "text-input"
      }
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="status"
      label="Status"
      name="status"
      autoComplete="status"
      value={value}
      onChange={change.bind(null, "status")}
      onBlur={handleBlur}
      select
    >
      {status.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Grid container wrap="nowrap" direction="row">
            <Grid item>
              <ListItemIcon>
                {option.icon && (
                  <option.icon color={option.color} fontSize="small" />
                )}
              </ListItemIcon>
            </Grid>
            <Grid>
              <Typography>{option.label}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </TextField>
  );
}
