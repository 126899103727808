import {host} from "../../../../../../config";
export const updateAssigned = (issue, userId) => {
  return async dispatch => {
    fetch(`//${host}/api/issues/${issue.id}`, {
      credentials: 'include',
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        ...issue,
        assigned: userId,
      })
    }).then(async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "ISSUE_UPDATED",
          issue: data[0],
        })
      }
    }).catch(e => {})
  }
}
