import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
}));

const TrackerIcon = ()=> {
  return (
    <SvgIcon viewBox="0 0 275.33 275.33">
      <path d="m137.67 168.02c16.737 0 30.354-13.617 30.354-30.354s-13.617-30.354-30.354-30.354-30.354 13.617-30.354 30.354 13.617 30.354 30.354 30.354zm0-48.708c10.121 0 18.354 8.233 18.354 18.354s-8.233 18.354-18.354 18.354-18.354-8.233-18.354-18.354 8.233-18.354 18.354-18.354z"/>
      <path d="m269.33 131.67h-23.775c-3.015-54.818-47.074-98.877-101.89-101.89v-23.775c0-3.313-2.687-6-6-6s-6 2.687-6 6v29.605c0 3.313 2.687 6 6 6 52.969 0 96.062 43.093 96.062 96.062s-43.093 96.062-96.062 96.062-96.062-43.093-96.062-96.062c0-36.783 21.452-70.817 54.651-86.704 2.989-1.431 4.253-5.013 2.822-8.002-1.43-2.988-5.015-4.252-8.002-2.822-18.131 8.676-33.473 22.217-44.366 39.158-10.11 15.724-15.897 33.718-16.924 52.37h-23.786c-3.313 0-6 2.687-6 6s2.687 6 6 6h23.775c3.015 54.818 47.074 98.877 101.89 101.89v23.775c0 3.313 2.687 6 6 6s6-2.687 6-6v-23.775c54.818-3.015 98.877-47.074 101.89-101.89h23.775c3.313 0 6-2.687 6-6s-2.687-6-6-6z"/>
    </SvgIcon>
  );
}
const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Tracker',
      href: '/tracker',
      icon: <TrackerIcon />
    },
    {
      title: 'Issues',
      href: '/issues',
      icon: <ReceiptIcon />
    },
    {
      title: 'Teams',
      href: '/teams',
      icon: <PeopleIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    },
    {
      title: 'Invite',
      href: '/invite',
      icon: <MailIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
