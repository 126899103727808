import React from "react";
import {
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
} from '@material-ui/icons';
import {IconButton, Tooltip}from '@material-ui/core';
export default function (props) {
  const {audio} = props;
  return (
    <Tooltip title={audio?"Mute your Audio": "Unmute your Audio"}>
      <IconButton size="small" onClick={props.onClick}>
        {!audio ? <VolumeOffIcon /> : <VolumeUpIcon/> }
      </IconButton>
    </Tooltip>
  )
}
