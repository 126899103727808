import React from "react";
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import IssueForm from '../IssueForm';
import {host} from '../../config';

export default function (props) {
  const [issue, setIssue] = React.useState(null)
  React.useEffect(()=>{
    const fetchData = async () => {
      fetch(`//${host}/api/issues/${props.match.params.id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }).then(async r => {
        if (r.status === 200){
          const data = await r.json()
          setIssue(data[0])
        }
      }).catch(e => {})
    }
    fetchData();
  }, [props.match.params.id])
  return (
    <Container maxWidth="sm">
      {issue && (
        <div>
          <IssueForm issue={issue}/>
        </div>
      )}
      {!issue && (
        <CircularProgress />
      )}
    </Container>
  )
}
