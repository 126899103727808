import React from 'react';
import {connect} from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Link, Typography } from '@material-ui/core';
import {getUser} from '../../../../../../views/SignIn/selectors'
import {host} from '../../../../../../config'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { account, className, ...rest } = props;
  const classes = useStyles();

  const user = {
    name: account.fname +' '+account.lname || 'Your Name',
    avatar: account.avatar,
    bio: account.title,
  };
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {user.avatar ? (
        <React.Fragment>
          {user.avatar.startsWith('http') ? (
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              src={account.avatar}
              to="/account"
            />
          ): (
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              src={`${process.env.REACT_APP_PROTOCOL}://${host}/static/${account.avatar}`}
              to="/account"
            />
          )}
        </React.Fragment>
      ) : (
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/account"
        />
      )}
      <Typography
        noWrap={true}
        className={classes.name}
        variant="h5"
      >
        {user.name}
      </Typography>
      <Typography variant="body2">
        {user.bio ? (
          <React.Fragment>{`"${user.bio}"`}</React.Fragment>
        ) : (
          <Link
            color="secondary"
            component={RouterLink}
             to="/account">Add a Title</Link>
        )}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default connect(state=>({
  account: getUser(state),
}),{})(Profile);
