import {authFetch} from "../../common/auth-fetch";
export function loadUsers () {
  return async dispatch => {
    authFetch(`/api/users`, {
        method: "GET",
      },
      async r => {
        if (r.status === 200){
          const data = await r.json()
          dispatch({
            type: "LOAD_USER_LIST",
            users: data
          });
        }
      }
    )
  }
}
