import React from 'react';
import {connect} from 'react-redux';
import {AssignToSelect} from "../../../../../../components/Issue/components"
import {updateAssigned} from "./actions";

function AssignToForm(props) {
  const {issue, users} = props
  const handleChange = (e) => {
    props.updateAssigned(issue, e.target.value)
  }
  return (
    <AssignToSelect
      value={issue.assigned||""}
      users={users} onChange={handleChange}/>
  );
}

export default connect(
  state => ({

  }),
  {updateAssigned}
)(AssignToForm)
