import React from 'react';
import { useHistory } from "react-router-dom";
import { SearchInput } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px',
  },
}));

export default function (props){
  const history = useHistory();
  const classes = useStyles();
  function handleClick() {
    history.push("/issues-form");
  }
  return (
    <Grid
      className={classes.root}
    container
      alignItems="center" direction="row" justify="space-between">
      <Grid item>
          <SearchInput
            placeholder="Search"
            onChange={e=>props.onSearchInputChange(e.target.value)}
          />
      </Grid>
      <Grid item>
        <Button
          id="add-issue-button"
          onClick={handleClick}
          color="primary"
          variant="contained"
        >
          Add Issue
        </Button>
      </Grid>
    </Grid>
  )
}
