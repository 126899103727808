import {authFetch} from '../../../../../../common/auth-fetch';

export function deleteTeam (id) {
  return async dispatch => {
    authFetch(`/api/teams/${id}`, {
      method: "DELETE",
    }, async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "LOAD_TEAM_LIST",
          teams: data
        });
      }
    })
    }
}
