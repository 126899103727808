import {getUsers} from "components/User/selectors"

export function getState(state){
  return state.meetingReducer;
}

export function getUserVideoSegments(state){
  return getState(state).userSegments;
}

export function getMeetingUsers(state){
  const users = getUsers(state);
  const meetingUsers = getState(state).meetingInfo.users;
  return users.filter(user=>meetingUsers.includes(user.id));
}

export function getMeetingWS(state){
  return getState(state).ws;
}
export function getMeetingInfoWS(state){
  return getState(state).meetingInfoWS;
}
export function getSegments(state){
  return getState(state).segments;
}
export function getMediaSource(state){
  return getState(state).mediaSource;
}
export function getActiveIdx(state){
  return getState(state).activeIdx;
}
export function getMeetingInfo(state){
  return getState(state).meetingInfo;
}
export function getPresenterId(state){
  return getMeetingInfo(state).presenterId;
}
export function getIsRecording(state){
  return getState(state).isRecording;
}
export function hasAudio(state){
  return !!getState(state).audio;
}
export function hasVideo(state){
  return !!getState(state).video;
}
export function getStream(state){
  return getState(state).stream;
}
