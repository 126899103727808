const initialState = {
  users: [],
  isLoading: true,
};

export function reducer(state=initialState, action){
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      }
    case "LOAD_USER_LIST":
      return {
        ...state,
        users: action.users,
        isLoading: false,
      }
    default:
      return state;
  }
}
