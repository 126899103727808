import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TeamsToolbar, TeamsTable } from './components';
import { authFetch } from '../../common/auth-fetch';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const TeamList = () => {
  const classes = useStyles();
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [term, setTerm] = useState("")
  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await authFetch(`/api/teams`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      },
      async r => {
        setIsLoading(false)
        if (r.status === 200){
          const data = await r.json()
          setTeams(data);
        }
      })
    }
    fetchData();
    return ()=>{}
  }, [])
  return (
    <div className={classes.root}>
      <TeamsToolbar onChange={(v)=>setTerm(v)}/>
      <div className={classes.content}>
        {!isLoading && (
          <TeamsTable
            teams={teams.filter(team=>team.name.indexOf(term) >= 0)}
          />
        )}
      </div>
    </div>
  );
};

export default TeamList;
