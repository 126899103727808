import React from 'react';
import {Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: props => props.height || 110,
    width: props => props.width || 100,
    flexShrink: 0,
    flexGrow: 0
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

export default (props) => {
  const {avatar} = props;
  const classes = useStyles(props);
  let src = null;
  if (avatar){
    if (avatar.startsWith('http')){
      src = avatar
    } else {
      src = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/static/${avatar}`;
    }
  }
  return (
    <Avatar className={classes.avatar} src={src} />
  )
}
