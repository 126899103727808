import {host} from '../../config';

export function setLocalStorageUser(data) {
  const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
  if (cookieDomain) {
    document.cookie = `ident=${data.token};Path=/;domain=${cookieDomain};`
  } else {
    document.cookie = `ident=${data.token};Path=/;`//domain=.co-lab.team;
  }
  localStorage.setItem('token', data.token)

  localStorage.setItem('email', data.email)
  localStorage.setItem('avatar', data.avatar || "")
  localStorage.setItem('fname', data.fname)
  localStorage.setItem('lname', data.lname)
  localStorage.setItem('title', data.title || "")
  localStorage.setItem('id', data.id)
}

export function signin({email, password}, history) {
  return async (dispatch) => {
    // dispatch(setLoading())
    fetch(`//${host}/signin`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        pass: password,
        email,
      })
    }).then(async r => {

      if (r.status === 200){
        const data = await r.json()
        setLocalStorageUser(data)
        dispatch({
          type: "SIGNIN_SUCCESS",
          user: data,
        })
        history.push('/dashboard')
      } else {
        dispatch({type: 'SIGNIN_ERROR'});
      }
    }).catch(e => {
      dispatch({type: 'SIGNIN_ERROR'});
    })
 }
}

export function logout() {
  localStorage.setItem("token", "")
  localStorage.setItem("email", "")
  localStorage.setItem("id", "")
  localStorage.setItem("fname", "")
  localStorage.setItem("lname", "")
  localStorage.setItem("avatar", "")
  localStorage.setItem("title", "")
  window.location = '/'
  return {
    type: "LOGOUT"
  }
}
