import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {
  Typography
} from '@material-ui/core';


export default (props) => {
  const {
    errors,
    touched,
    change,
    value,
    handleBlur,
  } = props
  return (
    <TextField
      className={
        errors.team && touched.team
          ? "text-input error"
          : "text-input"
      }
      variant="outlined"
      margin="normal"
      fullWidth
      id="issue-team-input"
      label="Team"
      name="team"
      autoComplete="team"
      value={value}
      onChange={change.bind(null, "team")}
      onBlur={handleBlur}
      select
    >
      {props.teams.map(option => (
        <MenuItem key={option.id} value={option.id}>
          <Typography>{option.name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}
