import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TeamRowMembers from './components/TeamRowMembers'
import TeamRowMenu from './components/TeamRowMenu'

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const TeamsTable = props => {
  const history = useHistory()
  const { className, teams, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell># Issues</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              {teams.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid alignItems="center"
                        direction="row" justify="center" container>
                        <Grid item>
                          <Typography>
                            You have no teams.  Why not go assemble one ?
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={()=>history.push('teams-form')}
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {teams.slice(0, rowsPerPage).map(team => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={team.id}
                    >
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                          >
                            {getInitials(team.name)}
                          </Avatar>
                          <Typography variant="body1">{team.name}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        {team.members && (
                          <TeamRowMembers members={team.members} />
                        )}
                      </TableCell>
                      <TableCell>0</TableCell>
                      <TableCell>
                        {moment(team.created).format('MM/DD/YYYY')}
                      </TableCell>
                      <TableCell><TeamRowMenu team={team} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={teams.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

TeamsTable.propTypes = {
  className: PropTypes.string,
  teams: PropTypes.array.isRequired
};

export default TeamsTable;
