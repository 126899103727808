import {authFetch} from 'common/auth-fetch';

export function deleteIssue (id) {
  return async dispatch => {
    authFetch(`/api/issues/${id}`, {
      method: "DELETE",
    }, async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "LOAD_ISSUES_LIST",
          issues: data
        });
      }
    })
  }
}
