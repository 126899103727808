import React from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {deleteIssue} from './actions';
const DELETE_ISSUE = 'Delete Issue';
const EDIT_ISSUE = 'Edit Issue';
const options = [
  EDIT_ISSUE,
  DELETE_ISSUE,
];

const ITEM_HEIGHT = 48;

function IssueRowMenu(props) {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isAlert, setIsAlert] = React.useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    if (action === DELETE_ISSUE){
      setIsAlert(true)
    } else if (action === EDIT_ISSUE){
      history.push('/issues-form/'+props.issue.id)
    }
    setAnchorEl(null);
  };
  const handleWarningClose = (isAgree) => {
    if (isAgree){
      props.deleteIssue(props.issue.id);
    }
    setIsAlert(false)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
          },
        }}
      >
        {options.map(option => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={()=>handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        open={isAlert}
        onClose={()=>handleWarningClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete this issue?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There is no turning back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleWarningClose(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={()=>handleWarningClose(true)} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(
  state => ({

  }),{
    deleteIssue
  }
)(IssueRowMenu)
