import React, { Component } from 'react';
import {connect} from "react-redux"
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux'
import validate from 'validate.js';
import store from './store'
import { chartjs } from './helpers';
import {generateTheme} from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import {getPrimaryColor, isDarkTheme} from "./theme/selectors";

// const browserHistory = createBrowserHistory({ basename: '/colab' });
const browserHistory = createBrowserHistory();


Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const UnconnectedApp = props => {
  React.useEffect(()=>{
    ReactGA.initialize('UA-116598472-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    browserHistory.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname)
      }
    );
  },[])
  return (
    <ThemeProvider theme={generateTheme(props.primaryColor, props.isDarkTheme)}>
      <CssBaseline />
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}
const ConnectedApp = connect(
  state => ({
    isDarkTheme: isDarkTheme(state),
    primaryColor: getPrimaryColor(state),
  }),
  {}
)(UnconnectedApp)

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedApp />
      </Provider>
    );
  }
}
