import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {MuteButton, VideoButton} from "components/Meeting/components";
import {
  PauseCircleOutline as PauseCircleOutlineIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
} from '@material-ui/icons';
import {
  getIsRecording,
  hasAudio,
  hasVideo,
} from "components/Meeting/selectors";
import {
  getUserMediaStream,
  setPresenterId,
  shareScreen,
  toggleAudio,
  toggleRecording,
  toggleVideo,
} from "components/Meeting/actions";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  placeholder: {
    height: 600,
    width: 800,
  },
  members: {
    margin: 10,
  },
  segment: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  userPreviewContent: {
    position: 'relative',
    height: 150,
  },
  noVideoAvatar: {
    position: 'absolute',
    top: 50,
    left: 75,
  },
  video: {
    position: 'absolute',
    borderRadius: 5,
  }
});
function UserPreview(props){
  const {
    account,
    audio,
    getUserMediaStream,
    isRecording,
    presenterId,
    setPresenterId,
    shareScreen,
    toggleAudio,
    toggleRecording,
    toggleVideo,
    video,
  } = props
  const isPresenting = presenterId === account.id;
  const classes = useStyles();
  let userPreview = React.useRef(null);
  React.useEffect(()=>{
    if (userPreview && userPreview.current){
      getUserMediaStream(userPreview.current, undefined, undefined, true)
    }
  }, [getUserMediaStream, userPreview])
  const host = process.env.REACT_APP_HOST;
  let src = `${process.env.REACT_APP_PROTOCOL}://${host}/static/${account.avatar}`
  if (account.avatar && account.avatar.startsWith('http')){
    src = account.avatar
  }
  return (
    <Grid item className={classes.members}>
        <Card className={classes.root} variant="outlined">
          <CardHeader
            avatar={video?<Grow in={video}><Avatar src={src} /></Grow>:null}
            title={`${account.fname} ${account.lname}`}
          />
          <CardContent className={classes.userPreviewContent}>
            <Grow in={!video}>
              <Avatar className={classes.noVideoAvatar} src={src} />
            </Grow>
            <video
              autoPlay
              className={classes.video}
              muted
              ref={userPreview}
              width="160"
              height="120"
            />
            {presenterId === account.id && (
              <div>User is Presenting</div>
            )}
          </CardContent>
          <CardActions>
            <Grid direction="row" container justify="center">
              <Grid item>
                <MuteButton
                  audio={audio}
                  onClick={()=>{
                    toggleAudio();
                    const startPlaying = audio === false && video === false;
                    if ( (!video && audio) || startPlaying ){
                      toggleRecording();
                    }
                    getUserMediaStream(
                      userPreview.current,
                      !audio,
                      video,
                      startPlaying,
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <VideoButton
                  video={video}
                  onClick={()=>{
                    const startPlaying = audio === false && video === false;
                    if (video && !audio){
                      toggleRecording();
                    } else if (startPlaying) {
                      toggleRecording()
                    }
                    toggleVideo();
                    getUserMediaStream(userPreview.current, audio, !video, startPlaying)
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Present">
                  {!isRecording ? (
                    <IconButton
                      onClick={()=>{
                        userPreview.current.play()
                        toggleRecording();
                      }}
                      size="small">
                      <PlayCircleOutlineIcon/>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={()=>{
                        userPreview.current.pause()
                        props.clearSegments();
                        toggleRecording();
                      }}
                      size="small"
                    >
                      <PauseCircleOutlineIcon color="error" />
                    </IconButton>
                )}
                </Tooltip>
              </Grid>
            </Grid>
          </CardActions>
          <Grid item>
            <Button
             onClick={()=> {
               if (isPresenting){
                  setPresenterId("")
               } else {
                  setPresenterId(account.id)
               }
             }}
             fullWidth>{!isPresenting?"Present":"Stop Presenting"}</Button>
             <Button
              onClick={shareScreen}
              fullWidth>
              {!isPresenting?"Share Screen":"Stop Sharing Screen"}
            </Button>
          </Grid>
        </Card>
    </Grid>
  )
}
export default connect(
  state => ({
    audio: hasAudio(state),
    isRecording: getIsRecording(state),
    video: hasVideo(state),
  }),
  {
    getUserMediaStream,
    setPresenterId,
    shareScreen,
    toggleAudio,
    toggleRecording,
    toggleVideo,
  }
)(UserPreview)
