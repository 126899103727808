const initialState = {
  isError: false,
  isLoggedIn: !!localStorage.getItem('token'),
  isLoading: false,
  user: {
    id: localStorage.getItem('id'),
    fname: localStorage.getItem('fname'),
    lname: localStorage.getItem('lname'),
    avatar: localStorage.getItem('avatar'),
    token: localStorage.getItem('token'),
    email: localStorage.getItem('email'),
    title: localStorage.getItem('title'),
  }
};

export function signinReducer(state=initialState, action){
  switch (action.type) {
    case "SET_USER_AVATAR":
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.avatar,
        }
      }
    case "SIGNIN_LOADING":
      return {
        ...state,
        isError: true,
        isLoading: true,
      }
    case "SIGNIN_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case "LOGOUT":
      return initialState;
    case "SIGNIN_SUCCESS":
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        user: action.user,
      }
    default:
      return state;
  }
}
