import { getIssuesState } from '../../selectors';
import { getUser } from '../SignIn/selectors';
export function getActiveIssues(state){
  const userId = getUser(state).id
  return getIssuesState(state).issues.filter(issue=>issue.assigned === userId);
}

export function getShippedIssues(state){
  return getIssuesState(state).issues.filter(issue=>issue.status === 3).length;
}
export function getTaskProgress(state){
  const results = getIssuesState(state).issues.reduce(
    (results, issue) =>{
      const bucket = issue.status === 1?'tasksInProgress':'totalTasks';
      return {
        ...results,
        [bucket]: results[bucket] + 1
      }
    },
    {tasksInProgress:0, totalTasks:0}
  );
  if (results.totalTasks===0){
    return 0;
  }
  return (results.tasksInProgress / results.totalTasks).toFixed(2);
}
