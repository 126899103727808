import React from 'react';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddIcon from '@material-ui/icons/Add';

import { ISSUE_STATUS, getIconForStatus } from 'components/Issue/components/Status/model';
import { ISSUE_PRIORITIES, getIconForPriority } from 'components/Issue/components/Priority/const';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestOrders = props => {
  const history = useHistory();
  const { className, issues, ...rest } = props;
  const classes = useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={()=>history.push('/issues-form')}
          >
            New Issue
          </Button>
        }
        title="Issues assigned to me"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Days Open</TableCell>
                </TableRow>
              </TableHead>
              {issues && issues.length > 0 ? (
                <TableBody>
                {issues.map(issue => {
                  const a = moment(issue.created)
                  const b = moment()
                  const daysSince = Math.abs(a.diff(b, 'days'))+1
                  return (
                    <TableRow
                      hover
                      key={issue.id}
                    >
                      <TableCell>{issue.title}</TableCell>
                      <TableCell>
                        <div className={classes.statusContainer}>
                          {ISSUE_PRIORITIES[issue.priority]}
                          <Divider style={{margin: '5px'}} flexItem orientation="vertical"/>
                          {getIconForPriority(issue.priority)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.statusContainer}>
                          {ISSUE_STATUS[issue.status]}
                          <Divider style={{margin: '5px'}} flexItem orientation="vertical"/>
                          {getIconForStatus(issue.status)}
                        </div>
                      </TableCell>
                      <TableCell>
                        {daysSince}
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid alignItems="center"
                        direction="row" justify="center" container>
                        <Grid item>
                          <Typography>
                            You have no issues.  Why not go create one ?
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={()=>history.push('issues-form')}
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={()=>history.push('/issues')}
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
