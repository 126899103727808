import {getIssueState} from "components/Issue/selectors";
import {ISSUE_STATUS} from "components/Issue/components/Status/model";
export function getIssuesByStatus(state){
  return getIssueState(state).issues.reduce((issues, issue) => ({
    ...issues,
    [ISSUE_STATUS[issue.status]]: issues[ISSUE_STATUS[issue.status]] ?
      [...issues[ISSUE_STATUS[issue.status]], issue] : [issue]
  }), {})
}
export function getIssueBoardWS(state){
  return state.issueBoardReducer.ws;
}
