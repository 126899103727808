import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  Select,
  InputLabel,
  Typography,
  Link
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {authFetch} from '../../common/auth-fetch';
import {getUsers} from "../../components/User/selectors"
import {loadUsers} from "../../components/User/actions"
import UserAvatar from "../../components/User/components/UserAvatar"

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 5,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TeamForm = props => {
  const history = useHistory();
  const {loadUsers, match, users} = props;
  const [team, setTeam] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(!!match.params.id)
  React.useEffect(() => {
    const load = async ()=>{
      authFetch(`/api/teams/${match.params.id}`,
        {},
        async r => {
          setTeam((await r.json()))
          setIsLoading(false);
        }
      )
    }
    if (match.params.id){
      load()
    }
    loadUsers()
  }, [loadUsers, match])
  const classes = useStyles();
  const handleBack = () => {
    history.goBack();
  }
  return (
    <div>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <Formik
          initialValues={{
            name: team && team.name ? team.name : "",
            members: team && team.members ? team.members.map(m=>m.user_id):[],
          }}
          onSubmit={async values => submit(values, history, match.params.id)}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldTouched,
            } = props;
            const change = (name, e) => {
              e.persist();
              handleChange(e);
              setFieldTouched(name, true, false);
              };
            return (
              <React.Fragment>
                {users.length === 0 ? (
                  <Typography>
                    You don't seem to have any other members.
                    Why not <Link component="a" href="/invite">Invite Some!</Link>
                  </Typography>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      className={
                        errors.name && touched.name
                          ? "text-input error"
                          : "text-input"
                      }
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Team Name"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      value={values.name}
                      onChange={change.bind(null, "name")}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name && (
                      <div className="input-feedback">{errors.name}</div>
                    )}
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-chip-label">Members</InputLabel>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        name="members"
                        value={values.members}
                        onChange={change.bind(null, "members")}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={selected => (
                          <div className={classes.chips}>
                            {selected.map(value => (
                              <Chip key={value}
                                avatar={<UserAvatar
                                  height={50}
                                  width={50}
                                  avatar=
                                  {users.reduce((l, u)=>{
                                      return l?l:u.id === value ? u.avatar : ""
                                    }, "")}
                                />}
                                label={users.reduce((l, u)=>{
                                    return l?l:u.id === value ? u.fname+" "+u.lname : ""
                                  }, "")}
                                className={classes.chip} />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {users.map(user => (
                          <MenuItem
                            key={user.id}
                            value={user.id}>
                            {user.fname}{" "}{user.lname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                        onClick={()=>{}}
                      >
                        Submit
                      </Button>

                      <Button
                        fullWidth
                        type="button"
                        color="secondary"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        Reset
                      </Button>
                    </form>
                  )}
              </React.Fragment>
            );
          }}
        </Formik>
      )}
      <Grid container justify="center">
        <Grid item>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

const submit = async(values, history, id) => {
  const url = id ? `/api/teams/${id}`:`/api/teams`;
  authFetch(url, {
    method: id ? "PUT" : "POST",
    body: JSON.stringify(values)
  },
  async r => {
    if (r.status === 200){
      history.push('/teams')
    }
  })
}
export default connect(
  state => ({
    users: getUsers(state),
  }),
  {loadUsers}
)(TeamForm)
