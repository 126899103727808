import {host} from '../../config';

import {setLocalStorageUser} from "../SignIn/actions";

export function googleAuth(){
  return async dispatch => {
    fetch(`//${host}/google-auth`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then(async r => {
      if (r.status === 200){
        const data = await r.json();
        window.location.href = data.url;
      }
    })
  }
}

export function googleAuthSuccess(code, history){
  return async dispatch => {
    fetch(`//${host}/google-auth-success?code=${code}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then(async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "SIGNIN_SUCCESS",
          user: data,
        })
        setLocalStorageUser(data)
        history.push('/dashboard')
      }
    })
  }
}
