import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {host} from "../../../../config";
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    height: 400,
    // maxWidth: 100,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: '24px',
    height: '24px'
  },
  divider: {
    margin: '0 12px'
  },
  assignTo: {
    minWidth: 200
  }
}));

function renderRow(props) {
  const { index, style } = props;

  return (
    <ListItem button style={style} key={index}>
      <ListItemText primary={`Item ${index + 1}`} />
    </ListItem>
  );
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

function AssignToSelect(props) {
  const classes = useStyles();
  const {users} = props
  let onChangeHandler = props.onChange;
  if (props.change){
    onChangeHandler = props.change.bind(null, "assigned")
  }
  if (!users || users.length === 0){
    return null;
  }
  return (
    <TextField
      className={classes.assignTo}
      variant="outlined"
      margin="normal"
      fullWidth
      id="assigned"
      label="Assigned To"
      name="assigned"
      autoComplete="assigned"
      value={props.value}
      onChange={onChangeHandler}
      select
    >
    <MenuItem key="unassigned" value={""}>
      <ListItemIcon>
        <Avatar
          className={classes.avatar}
        />
      </ListItemIcon>
      <Typography variant="subtitle1">Unassigned</Typography>
    </MenuItem>
    {users.map(option => {
      let src = `${process.env.REACT_APP_PROTOCOL}://${host}/static/${option.avatar}`
      if (option.avatar && option.avatar.startsWith('http')){
        src = option.avatar
      }
      return (
        <MenuItem key={option.id} value={option.id}>
          <Grid container wrap="nowrap" alignItems="center" direction="row">
            <Grid item>
              <ListItemIcon>
                <Avatar
                  className={classes.avatar}
                  src={src}
                />
              </ListItemIcon>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {option.fname +" "+option.lname}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      );
    })}
    </TextField>
  );
}

export default connect(
  state => ({
  }),
  {}
)(AssignToSelect)
