import React from "react";
import {
  Videocam as VideocamIcon,
  VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import {IconButton, Tooltip}from '@material-ui/core';
export default function (props) {
  return (
    <Tooltip title="Video">
      <IconButton onClick={props.onClick} size="small">
        {!props.video ? (
          <VideocamOffIcon />
        ) : (
          <VideocamIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}
