import React from "react";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import FlagIcon from '@material-ui/icons/Flag';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HelpIcon from '@material-ui/icons/Help';

export const ISSUE_PRIORITIES = [
  'Triage',
  'Critical',
  'Major',
  'Minor',
  'Trivial',
]

export function getIconForPriority(idx){
  switch (idx){
    case 0:
      return (<LocalHospitalIcon />)
    case 1:
      return (<PriorityHighIcon color="error" />)
    case 2:
      return (<TrendingUpIcon />)
    case 3:
      return (<TrendingDownIcon />)
    case 4:
      return (<FlagIcon />)
    default:
      return (<HelpIcon />)
  }
}
