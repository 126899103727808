import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';

export default function (props) {
  const {members} = props;
  if (!members){
    return <div>0</div>;
  }
  let hidden = 0
  if (members.length > 3){
    hidden = members.length - 3;
  }
  return (
    <AvatarGroup>
      {props.members.slice(0,3).map(member => (
        <Avatar
          key={member.user_id}
          src={member.avatar? `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/static/${member.avatar}`:null}/>
      ))}
      {hidden > 0 && (
        <Tooltip title={members.slice(3).map(m=>m.fname+" "+m.lname).join(" • ")}>
          <Avatar>+{hidden}</Avatar>
        </Tooltip>
      )}
    </AvatarGroup>
  );
}
