import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {host} from '../../config'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const InviteForm = () => {
  const classes = useStyles();
  return (
    <div>
      <Formik
        initialValues={{ name: "", members: []}}
        onSubmit={async values => {
          // await new Promise(resolve => setTimeout(resolve, 500));
          // alert(JSON.stringify(values, null, 2));
          submit(values);
        }}
        validationSchema={Yup.object().shape({
          desc: Yup.string().required("Required"),
          title: Yup.string().required("Required"),
          priority: Yup.string().required("Required"),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldTouched,
          } = props;
          const change = (name, e) => {
            e.persist();
            handleChange(e);
            setFieldTouched(name, true, false);
            };
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                className={
                  errors.name && touched.name
                    ? "text-input error"
                    : "text-input"
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={values.email}
                onChange={change.bind(null, "email")}
                onBlur={handleBlur}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                onClick={()=>{}}
              >
                Submit
              </Button>

              <Button
                fullWidth
                type="button"
                color="secondary"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >
                Reset
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

const submit = async(values) => {
  fetch(`//${host}/api/invite`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(values)
  }).then(async r => {
    console.log(r.status)
    if (r.status === 200){
      // const data = await r.json()
    }
  }).catch(e => {})
}
