import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Grid,
} from '@material-ui/core';
const useStyles = makeStyles({
  divider: {
    margin: '5px',
    height: '80px'
  },
  placeholder: {
    height: 600,
    width: 800,
  },
  segmentContainer: {
    position: 'relative',
    height: 600,
    width: 800,
    marginTop: 10,
    marginLeft: 10,
  },
  segment: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
});
const protocol = process.env.REACT_APP_PROTOCOL;
const host = process.env.REACT_APP_HOST
export default function (props){
  const classes = useStyles();
  const {presenterId, segments} = props;
  let segmentsToShow = segments
  // if (segments && segments.length > 2) {
  //   segmentsToShow = segments.slice(segments.length-2, segments.length);
  // }
  return (
    <Grid container direction="column">
      <Grid item className={classes.segmentContainer}>
        <div>
          <Grid className={classes.placeholder}
            container justify="center" alignItems="center">
            {!presenterId ? (
              <React.Fragment>
                <Grid item>
                  <img
                     alt="No Presenter"
                     src="/images/spinners/communications.svg"/>
                </Grid>
                <Grid item>
                <Divider
                   className={classes.divider} orientation="vertical" flexItem />
                </Grid>
                <Grid item>
                  No one is currently presenting.
                </Grid>
              </React.Fragment>
            ) : (
              <div>{presenterId} is presenting but has no video</div>
            )}
          </Grid>
        </div>
        {presenterId && segmentsToShow && segmentsToShow.map((src, idx) => (
          <React.Fragment key={idx}>
            <div className={classes.segment}>
                <video
                  autoPlay
                  preload="auto"
                  poster=""
                  src={`${protocol}://${host}/static/${presenterId}/${src}`}
                  width="800" height="600" />
            </div>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}
