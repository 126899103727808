import React from 'react';
import {connect} from "react-redux";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography
} from '@material-ui/core';
import {setPrimaryColor} from './actions';

const useStyles = makeStyles((theme) => ({
  redButton: {
    color: 'red'
  },
  greenButton: {
    color: 'green'
  },
  lightGreenButton: {
    color: 'lightgreen',
  },
  limeButton: {
    color: '#cddc39'
  },
  lightBrownButton: {
    color: '#8d6e63'
  },
  orangeButton: {
    color: 'orange'
  },
  purpleButton: {
    color: 'purple',
  },
  blueButton: {
    color: 'blue',
  },
  lightBlueButton: {
    color:"#03a9f4",
  },
  blueGreyButton: {
    color:"#607d8b",
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Interface = props => {
  const { className, setPrimaryColor, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Manage the interface settings"
          title="Interface"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                Colors
              </Typography>
            </Grid>
            {/*
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                Messages
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Push Notifications"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Phone calls"
              />
            </Grid>
            */}
          </Grid>
          <div className={classes.buttonGroup}>
            <ButtonGroup
              variant="text"
               color="primary" aria-label="text primary button group">
              <Button
                onClick={()=>setPrimaryColor("RED")}
              className={classes.redButton}>Red</Button>
              <Button
                onClick={()=>setPrimaryColor("GREEN")}
               className={classes.greenButton}>Green</Button>
               <Button
                 onClick={()=>setPrimaryColor("LIGHT_GREEN")}
                className={classes.lightGreenButton}>Light Green</Button>
              <Button
                onClick={()=>setPrimaryColor("ORANGE")}
              className={classes.orangeButton}>Orange</Button>
              <Button
                onClick={()=>setPrimaryColor("LIME")}
              className={classes.limeButton}>Lime</Button>
              <Button
                onClick={()=>setPrimaryColor("PURPLE")}
              className={classes.purpleButton}>Purple</Button>
              <Button
                onClick={()=>setPrimaryColor("BLUE")}
               className={classes.blueButton}>Blue</Button>
              <Button
                 onClick={()=>setPrimaryColor("LIGHT_BLUE")}
                className={classes.lightBlueButton}>Light Blue</Button>
             <Button
              onClick={()=>setPrimaryColor("BLUE_GREY")}
              className={classes.blueGreyButton}>Blue Grey</Button>
              <Button
               onClick={()=>setPrimaryColor("LIGHT_BROWN")}
               className={classes.lightBrownButton}>Light Brown</Button>
            </ButtonGroup>
          </div>
        </CardContent>
      </form>
    </Card>
  );
};

export default connect(
  state => ({

  }), {setPrimaryColor}
)(Interface)
