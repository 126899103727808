
export function connectIssueBoardWS(){
  return async dispatch => {
    const ws = new WebSocket(`${process.env.REACT_APP_PROTOCOL==="https"?"wss":"ws"}://${process.env.REACT_APP_HOST}/api/issue-board`);
    ws.onopen = () => {
      dispatch({
        type: "SET_ISSUE_BOARD_WS",
        ws: ws,
      })
    }
    ws.onmessage = (events) => {
      dispatch({
        type: "LOAD_ISSUES_LIST",
        issues: JSON.parse(events.data)
      })
    }
  }
}
