import React from 'react';
import {InviteForm} from "./InviteForm";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
export default () => (
  <Container maxWidth="sm">
    <Typography variant="h1">Invite a colleague</Typography>
    <InviteForm />
  </Container>
)
