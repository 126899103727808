import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import {host} from 'config'
import {authFetch} from 'common/auth-fetch'
import UserAvatar from '../../../../components/User/components/UserAvatar'

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  input: {
    display: 'none',
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const { className, setUserAvatar, ...rest } = props;

  const classes = useStyles();

  const user = {
    name: rest.user.fname + " " + rest.user.lname,
    country: 'USA',
    timezone: 'GTM-5',
    avatar: rest.user.avatar,
  };

  const uploadAvatar = async (selectedFile) => {
    const data = new FormData()
    data.append('avatar', selectedFile)
    fetch(`//${host}/api/upload-avatar/${rest.user.id}`, {
      credentials: "include",
      method: "POST",
      body: data,
    }).then(async r => {
      if (r.status === 200){
        const data = await r.json()
        setUserAvatar(data.avatar)
      }
    }).catch(e => {
      console.log(e)
    })
  }

  const removeAvatar = async (selectedFile) => {
    const data = new FormData()
    data.append('avatar', selectedFile)
    authFetch(`/api/upload-avatar/${rest.user.id}`, {
      method: "DELETE",
    }, async r => {
      if (r.status === 200){
        const data = await r.json()
        setUserAvatar(data.avatar)
      }
    })
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {user.name}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.country}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              Member Since: {moment().format('hh:mm A')} ({user.timezone})
            </Typography>
          </div>
          <UserAvatar avatar={user.avatar} />
        </div>
      </CardContent>
      <Divider />
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          name="avatar"
          onChange={(event)=>{
            uploadAvatar(event.target.files[0])
          }}
        />
      <CardActions>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text"
          >
            <label htmlFor="contained-button-file">Upload picture</label>
          </Button>
        <Button
            onClick={removeAvatar}
           variant="text">Remove picture</Button>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};
export default AccountProfile
