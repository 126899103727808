import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PrioritySelect from 'components/Issue/components/Priority/components/PrioritySelect'
import {authFetch} from "common/auth-fetch";

export default props => {
  const {issue} = props;
  return (
    <Formik
      initialValues={{priority: issue.priority}}
      onSubmit={async values => {
        console.log(values)
      }}
      validationSchema={Yup.object().shape({
        priority: Yup.string().required("Required"),
      })}
    >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldTouched,
          } = props;
          const change = (name, e) => {
            e.persist();
            handleChange(e);
            setFieldTouched(name, true, false);
            updateIssue(issue, {priority: e.target.value})
          };
          return (
            <PrioritySelect
              change={change}
              handleBlur={handleBlur}
              value={values.priority}
              touched={touched}
              errors={errors}
            />
          )
        }}
    </Formik>
  )
}
const updateIssue = async(issue, newValues) => {
  authFetch(`/api/issues/${issue.id}`, {
    method: "PUT",
    body: JSON.stringify({
      ...issue,
      ...newValues,
    })
  }, async r => {
    if (r.status === 200){
      // const data = await r.json()
    }
  })
}
