const initialState = {
  issues: [],
  users: [],
  isLoading: true,
  searchTerm: "",
};

export function reducer(state=initialState, action){
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      }
    case "SET_ISSUE_SEARCH_TERM":
      return {
        ...state,
        searchTerm: action.searchTerm,
      }
    case "LOAD_ISSUES_LIST":
      return {
        ...state,
        issues: action.issues,
        isLoading: false,
      }
    case "ISSUE_UPDATED":
      return {
        ...state,
        issues: state.issues.map(issue=>issue.id === action.issue.id?action.issue:issue)
      }
    case "LOAD_USER_LIST":
      return {
        ...state,
        users: action.users,
      }
    default:
      return state;
  }
}
