import {
  getMeetingWS,
  getMeetingInfoWS,
  getStream,
  hasAudio,
  hasVideo,
  getIsRecording,
} from "./selectors";
import {startRecording as _startRecording} from './controls';

export function connectMeetingWS(id, userId){
  return async (dispatch, getState) => {
    const protocol = process.env.REACT_APP_PROTOCOL==="https"?"wss":"ws";
    const host = process.env.REACT_APP_HOST
    const ws = new WebSocket(`${protocol}://${host}/api/meeting/${id}/${userId}`);
    ws.onopen = ()=>{
      dispatch(connectMeetingInfoWS(id))
    }
    ws.onmessage = async (events) => {
      dispatch({
        type: 'ADD_USER_SEGMENT',
        segment: events.data,
        userId
      })
      dispatch(addUserActiveIndex(userId))
    }
    dispatch({
      type: "SET_MEETING_WS",
      ws: ws
    })
  }
}
export function connectUserStream(meetingId, userId){
  return async (dispatch, getState) => {
    const protocol = process.env.REACT_APP_PROTOCOL==="https"?"wss":"ws";
    const host = process.env.REACT_APP_HOST
    const ws = new WebSocket(`${protocol}://${host}/api/meeting/${meetingId}/${userId}`);
    ws.onmessage = async (events) => {
      dispatch({
        type: 'ADD_USER_SEGMENT',
        segment: events.data,
        userId
      })
      dispatch(addUserActiveIndex(userId))
    }
  }
}
export function connectMeetingInfoWS(id){
  return async (dispatch, getState) => {
    const protocol = process.env.REACT_APP_PROTOCOL==="https"?"wss":"ws";
    const host = process.env.REACT_APP_HOST
    const ws = new WebSocket(`${protocol}://${host}/api/meeting-info/${id}`);
    ws.onopen = async(events) => {
      ws.send(JSON.stringify({
        type: "GET_MEETING_INFO"
      }))
    }
    ws.onmessage = async (events) => {
      dispatch({
        type: "SET_MEETING_INFO",
        info: JSON.parse(events.data)
      })
    }
    dispatch({
      type: "SET_MEETING_INFO_WS",
      ws: ws
    })
  }
}
export function addActiveIndex(){
  return {
    type: "ADD_ACTIVE_INDEX"
  }
}
export function addUserActiveIndex(){
  return {
    type: "ADD_USER_ACTIVE_INDEX"
  }
}
export function clearSegments(){
  return {
    type: "CLEAR_MEETING_SEGMENTS",
  }
}
export function setPresenterId(id) {
  return (dispatch, getState) => {
    const infoWS = getMeetingInfoWS(getState())
    infoWS.send(JSON.stringify({
      type:"SET_PRESENTER",
      id,
    }))
  }
}
export function toggleRecording(){
  return {
    type: 'TOGGLE_IS_RECORDING'
  }
}
export function toggleAudio(){
  return {
    type: 'TOGGLE_AUDIO'
  }
}
export function toggleVideo(){
  return {
    type: 'TOGGLE_VIDEO'
  }
}

let recordingTimeMS = 5000;
export const sendData = (recordedChunks, stream) => {
  return async (dispatch, getState) => {
    let blob = new Blob(recordedChunks, { type: "video/webm" });
    console.log('sending data ', blob)
    if (blob.size > 0){
      const text = await blob.arrayBuffer();
      const state = getState();
      const ws = getMeetingWS(state)
      ws && ws.send(text)
      dispatch(startRecording(stream))
    }
  }
};
export const startRecording = stream => {
  return async (dispatch, getState) => {
    const state = getState();
    console.log('Starting Recording')
    if (state.meetingReducer.isRecording){
      return _startRecording(
        stream,
        recordingTimeMS
      ).then(async (c)=>dispatch(await sendData(c, stream)));
    }
  }
}

const displayMediaOptions = {
  video: {
    cursor: "hover"
  },
  audio: true,
};

export function shareScreen() {
  return async (dispatch, getState) => {
    const state = getState();
    const isRecording = getIsRecording(state);
    let captureStream = null;
    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    } catch(err) {
      console.error("Error: " + err);
    }
    if (isRecording){
      return _startRecording(
        captureStream,
        recordingTimeMS
      ).then(async (c)=>dispatch(await sendData(c, captureStream)));
    }
  }
}
export function getUserMediaStream(video, audio, _video, isStart) {
  return async (dispatch, getState) => {
    const stream = getStream(getState())
    if (stream){
      stream.getTracks().forEach(track => track.stop());
    }
    const isAudio = audio || hasAudio(getState())
    const isVideo = _video || hasVideo(getState())
    if (!!isAudio === false && !!isVideo === false){
      video.src = "";
      return;
    }
    console.log('Getting User Media Stream ', isStart)
    navigator.mediaDevices.getUserMedia({
      video: isVideo,
      audio: isAudio,
    }).then(stream => {
      dispatch({
        type:"SET_STREAM",
        stream
      })
      video.srcObject = stream;
      video.captureStream = video.captureStream || video.mozCaptureStream;
      return new Promise(resolve=> video.onplaying = resolve)
    })
    .then(async ()=>{
      if (isStart){
        await dispatch(startRecording(video.captureStream()))
      }
    })
    .catch(e=>console.log('Error with user media :', e));
  }
}
