import React from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {authFetch} from "../../common/auth-fetch";
import PrioritySelect from '../../components/Issue/components/Priority/components/PrioritySelect'
import StatusSelect from '../../components/Issue/components/Status/components/StatusSelect'
import {AssignToSelect} from '../../components/Issue/components';
import {TeamSelect} from '../../components/Team/components';
import {loadTeams} from '../../components/Team/actions';
import {loadUsers} from '../../components/User/actions';
import {getTeams} from '../../components/Team/selectors';
import {getUsers} from '../../components/User/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const IssueForm = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {match, teams, users, loadTeams, loadUsers} = props;
  const [issue, setIssue] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(!!match.params.id)
  React.useEffect(()=>{
    const loadIssue = async (id) => {
      authFetch(`/api/issues/${id}`,
        {},
        async r => {
          const data = await r.json();
          setIssue(data[0])
          setIsLoading(false);
        }
      )
    };
    if (match.params.id) {
      loadIssue(match.params.id)
    }
    loadUsers();
    loadTeams();
  },[loadTeams, loadUsers, match])

  const handleBack = () => {
    history.goBack();
  }
  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Formik
          initialValues={{
            title: issue.title || "",
            description: issue.description || "",
            priority: issue.priority || "0",
            status: issue.status || "0",
            assigned: issue.assigned || "",
            team: issue.team || ""
          }}
          onSubmit={async values => {
            await submit(values, history, issue ? issue.id : undefined);
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Required"),
            title: Yup.string().required("Required"),
            priority: Yup.string().required("Required"),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldTouched,
            } = props;
            const change = (name, e) => {
              e.persist();
              handleChange(e);
              setFieldTouched(name, true, false);
            };
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  className={
                    errors.title && touched.title
                      ? "text-input error"
                      : "text-input"
                  }
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  autoComplete="title"
                  autoFocus
                  value={values.title}
                  onChange={change.bind(null, "title")}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
                <TextField
                  className={
                    errors.description && touched.description
                      ? "text-input error"
                      : "text-input"
                  }
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="description"
                  value={values.description}
                  onChange={change.bind(null, "description")}
                  onBlur={handleBlur}
                  multiline
                  rowsMax="4"
                />
                {errors.description && touched.description && (
                  <div className="input-feedback">{errors.description}</div>
                )}
                <PrioritySelect
                  change={change}
                  handleBlur={handleBlur}
                  value={values.priority}
                  touched={touched}
                  errors={errors}
                />
                <StatusSelect
                  change={change}
                  handleBlur={handleBlur}
                  value={values.status}
                  touched={touched}
                  errors={errors}
                />
                <AssignToSelect
                  change={change}
                  handleBlur={handleBlur}
                  value={values.assigned}
                  touched={touched}
                  errors={errors}
                  users={users}
                />
              <TeamSelect
                  change={change}
                  handleBlur={handleBlur}
                  value={values.team}
                  touched={touched}
                  errors={errors}
                  teams={teams}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                  onClick={()=>{}}
                >
                  Submit
                </Button>

                <Button
                  fullWidth
                  type="button"
                  color="secondary"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  Reset
                </Button>
              </form>
            )}
          }
        </Formik>
      )}
      <Grid container justify="center">
        <Grid item>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
export default connect(
  state => ({
    teams: getTeams(state),
    users: getUsers(state),
  }),
  {loadTeams, loadUsers}
)(IssueForm)

const submit = async(values, history, id) => {
  const url = id ? `/api/issues/${id}`:`/api/issues`;
  authFetch(url, {
    method: id ? "PUT": "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(values)
  },
  async r => {
    if (r.status === 200){
      history.push("/issues")
    }
  })
}
