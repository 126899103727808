import {host} from "../config";
import {logout} from "../views/SignIn/actions";
export const authFetch = async (path, options, cb) => {
  if (path.startsWith('/')){
    path = path.slice(1);
  }
  const fetchOpts = {
    ...options,
    credentials: 'include',
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    method: options.method || "GET",
  };
  if (options.body){
    fetchOpts.body = options.body
  }
  await fetch(`//${host}/${path}`, fetchOpts)
  .then(async r => {
    if ([403, 401].includes(r.status)){
      logout();
      throw new Error("Auth Failed")
    } else {
      return r;
    }
  })
  .then(cb).catch(e => {
    console.log(e)
  })
}
