import {host} from "../../config";

export function loadIssues () {
  return async dispatch => {
    fetch(`//${host}/api/issues`, {
      credentials: 'include',
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then(async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "LOAD_ISSUES_LIST",
          issues: data
        });
      }
    }).catch(e => {})
    }
  }

export function handleSearchInputChanges(searchTerm){
  return {
    type: "SET_ISSUE_SEARCH_TERM",
    searchTerm
  }
}

export function loadUsers () {
  return async dispatch => {
    fetch(`//${host}/api/users`, {
      credentials: 'include',
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then(async r => {
      if (r.status === 200){
        const data = await r.json()
        dispatch({
          type: "LOAD_USER_LIST",
          users: data
        });
      }
    }).catch(e => {})
    }
  }
