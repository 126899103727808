import React, { useState } from 'react';
import {useHistory} from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import AssignToForm from './components/AssignToForm';
import IssueRowMenu from './components/IssueRowMenu';
import PriorityForm from './components/PriorityForm';
import StatusForm from './components/StatusForm';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const IssuesTable = props => {
  const history = useHistory();
  const { className, issues, users, ...rest } = props;

  const classes = useStyles();

  const [selectedIssues, setSelectedIssues] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { issues } = props;

    let selectedIssues;

    if (event.target.checked) {
      selectedIssues = issues.map(user => user.id);
    } else {
      selectedIssues = [];
    }

    setSelectedIssues(selectedIssues);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIssues.indexOf(id);
    let newSelectedIssues = [];

    if (selectedIndex === -1) {
      newSelectedIssues = newSelectedIssues.concat(selectedIssues, id);
    } else if (selectedIndex === 0) {
      newSelectedIssues = newSelectedIssues.concat(selectedIssues.slice(1));
    } else if (selectedIndex === selectedIssues.length - 1) {
      newSelectedIssues = newSelectedIssues.concat(selectedIssues.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIssues = newSelectedIssues.concat(
        selectedIssues.slice(0, selectedIndex),
        selectedIssues.slice(selectedIndex + 1)
      );
    }

    setSelectedIssues(newSelectedIssues);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIssues.length === issues.length}
                      color="primary"
                      indeterminate={
                        selectedIssues.length > 0 &&
                        selectedIssues.length < issues.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Assigned</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {issues.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Grid alignItems="center"
                        direction="row" justify="center" container>
                        <Grid item>
                          <Typography>
                            You have no issues.  Why not go create one ?
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={()=>history.push('issues-form')}
                          >
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {issues.slice(0, rowsPerPage).map(issue => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={issue.id}
                      selected={selectedIssues.indexOf(issue.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedIssues.indexOf(issue.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, issue.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <AssignToForm issue={issue} users={users} />
                      </TableCell>
                      <TableCell>
                        {issue.title}
                      </TableCell>
                      <TableCell>{issue.description}</TableCell>
                      <TableCell>
                        <PriorityForm issue={issue}/>
                      </TableCell>
                      <TableCell>
                        <StatusForm issue={issue}/>
                      </TableCell>
                      <TableCell>
                        {moment(issue.created).format('MM/DD/YYYY')}
                      </TableCell>
                      <TableCell>
                        <IssueRowMenu issue={issue}/>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={issues.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

IssuesTable.propTypes = {
  className: PropTypes.string,
  issues: PropTypes.array.isRequired
};

export default IssuesTable;
