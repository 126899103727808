import React from 'react';
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {getUser} from '../../views/SignIn/selectors'
import { AccountProfile, AccountDetails } from './components';
import {updateAccount} from './actions'
import {setUserAvatar} from './actions';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile
            setUserAvatar={props.setUserAvatar}
            user={props.user}/>
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails updateAccount={props.updateAccount}
            user={props.user} />
        </Grid>
      </Grid>
    </div>
  );
};

export default connect(state=>({
  user: getUser(state),
}),{updateAccount, setUserAvatar})(Account);
